import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2 {...{
      "id": "healthpoint-supported-claims",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#healthpoint-supported-claims",
        "aria-label": "healthpoint supported claims permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HealthPoint supported claims`}</h2>
    <p>{`Multiple transaction types are supported including:`}</p>
    <ul>
      <li parentName="ul">{`Claim`}</li>
      <li parentName="ul">{`Cancel (void)`}</li>
      <li parentName="ul">{`Rebate estimate (quote)`}</li>
    </ul>
    <h3 {...{
      "id": "professions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#professions",
        "aria-label": "professions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Professions`}</h3>
    <p>{`The following health professions are supported by all `}<a parentName="p" {...{
        "href": "https://help.medipass.com.au/en/articles/5812784-participating-tyro-healthpoint-funds"
      }}>{`participating Tyro HealthPoint`}</a>{` funds:`}</p>
    <ul>
      <li parentName="ul">{`Chiropractic`}</li>
      <li parentName="ul">{`Dental`}</li>
      <li parentName="ul">{`Dietetics`}</li>
      <li parentName="ul">{`Occupational Therapy`}</li>
      <li parentName="ul">{`Optometry`}</li>
      <li parentName="ul">{`Osteopathy`}</li>
      <li parentName="ul">{`Physiotherapy`}</li>
      <li parentName="ul">{`Podiatry`}</li>
      <li parentName="ul">{`Speech Pathology`}</li>
    </ul>
    <p>{`The following health professions are supported by some (but not all) health funds. Please refer to the `}{`"`}{`HealthPoint Providers`}{`"`}{` section `}<a parentName="p" {...{
        "href": "http://www.dedalus.com/anz/healthpoint"
      }}>{`here`}</a>{` for full cover details:`}</p>
    <ul>
      <li parentName="ul">{`Acupuncture`}</li>
      <li parentName="ul">{`Audiology`}</li>
      <li parentName="ul">{`Counselling`}</li>
      <li parentName="ul">{`Exercise Physiology`}</li>
      <li parentName="ul">{`GP and Medical (for Overseas health insurance claiming)`}</li>
      <li parentName="ul">{`Myotherapy`}</li>
      <li parentName="ul">{`Psychology`}</li>
      <li parentName="ul">{`Remedial Massage`}</li>
    </ul>
    <h3 {...{
      "id": "health-funds",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#health-funds",
        "aria-label": "health funds permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Health funds`}</h3>
    <p>{`HealthPoint supports private health insurance claiming for over 98% of the Australian population with such coverage. Participating funds include:`}</p>
    <ul>
      <li parentName="ul">{`AAMI`}</li>
      <li parentName="ul">{`ACA Health`}</li>
      <li parentName="ul">{`ADF Family Health`}</li>
      <li parentName="ul">{`ahm`}</li>
      <li parentName="ul">{`Apia`}</li>
      <li parentName="ul">{`Australian Unity`}</li>
      <li parentName="ul">{`Bupa`}</li>
      <li parentName="ul">{`CBHS Health and CBHS Corporate`}</li>
      <li parentName="ul">{`cua Health`}</li>
      <li parentName="ul">{`Defence Health`}</li>
      <li parentName="ul">{`Emergency Services Health`}</li>
      <li parentName="ul">{`Frank Health Insurance (which includes health.com.au)`}</li>
      <li parentName="ul">{`GMBHA`}</li>
      <li parentName="ul">{`HBF`}</li>
      <li parentName="ul">{`HCF`}</li>
      <li parentName="ul">{`Health Partners`}</li>
      <li parentName="ul">{`hif`}</li>
      <li parentName="ul">{`Latrobe Health`}</li>
      <li parentName="ul">{`Medibank`}</li>
      <li parentName="ul">{`Navy Health`}</li>
      <li parentName="ul">{`nib`}</li>
      <li parentName="ul">{`Onemedifund`}</li>
      <li parentName="ul">{`Peoplecare`}</li>
      <li parentName="ul">{`Phoenix Health`}</li>
      <li parentName="ul">{`Police Health`}</li>
      <li parentName="ul">{`Qantas Assure`}</li>
      <li parentName="ul">{`Queensland Country Health`}</li>
      <li parentName="ul">{`rt health`}</li>
      <li parentName="ul">{`Suncorp`}</li>
      <li parentName="ul">{`Teachers Health Fund`}</li>
      <li parentName="ul">{`Territory Health Fund`}</li>
      <li parentName="ul">{`TUH`}</li>
      <li parentName="ul">{`Uni Health Insurance`}</li>
      <li parentName="ul">{`union health`}</li>
      <li parentName="ul">{`Westfund`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      